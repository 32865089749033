import React, { Component, Suspense, lazy } from "react";
import { BrowserRouter as Router, Switch,
   Route } from "react-router-dom";
   import { Skeleton } from 'antd'
import cookie from "react-cookies";
import { PulseSpinner} from "react-spinners-kit";
const Notfound = lazy(() => import("./Common/Notfound"));
const ResourceNotfound = lazy(() => import("./Common/ResourceNotFound"));
const Login = lazy(() => import("./Login"));
const AmtSignup=lazy(()=>import('./Amateur/Signup'));
const MusicSignup=lazy(()=>import('./Music/Signup'));
const SchoolSignup=lazy(()=>import('./School/Signup'));
const PublisherSignup=lazy(()=>import('./Publisher/Signup'));
const SchoolThankyou = lazy(() => import("./Amateur/SchoolThankyou"));
const EduSignup=lazy(()=>import('./Education/Signup'));
const AmtThanks=lazy(()=>import('./Amateur/Thankyou'));
const PublisherThanks=lazy(()=>import('./Amateur/PublisherThankyou'));
const Forget = lazy(() => import("./Forget"));
const Msg = lazy(() => import("./Forget/Msg"));
const Activate = lazy(() => import("./Forget/Activate"));
const Main = lazy(()=>import('./Main'))
const Detail =lazy(()=>import('./Main/tabs'))
const Account =lazy(()=>import('./Main/AccountTab'))
const Licence =lazy(()=>import('./Main/LicenceTab'))
const Resource =lazy(()=>import('./Main/ResourceTab'))
const Listing =lazy(()=>import('./Main/ListingTab'))
const Update =lazy(()=>import('./Forget/Update'))
const Dashboard = lazy(()=>import('./Dashboard/index'))
const Terms =lazy(()=>import('./Terms'))
const Privacy =lazy(()=>import('./Privacy'))
const Licencee =lazy(()=>import('./Main/TabComponents/Licence/detail'))
const Theatre = lazy(()=>import('./Theatre/index'))
class Routes extends Component {
  state = {
    token: cookie.load("api_token"),
  };
  componentWillMount() {
    this.setState({
      token: cookie.load("api_token"),
      test:"ee"
    });
  }

  render() {
    const { token } = this.state;
    return (
      <Router>
        <Suspense
          fallback={
            <div  className="container"style={{ textAlign: "center"  }} >
              <div className="row mt-5">
              <Skeleton  paragraph={{ rows: 8}}/>
              </div>
          
            </div>
          }
        >
          <Switch>
            <Route exact path={"/"} render={(props) => <Main {...props} />} />
                
            <Route
              exact
              path={"/Forgot"}
              render={(props) => <Forget {...props} />}
            />
            <Route
              exact
              path={"/Terms"}
              render={(props) => <Terms {...props} />}
            />
           
            <Route
              exact
              path={"/Message/:email"}
              render={(props) => <Msg {...props} />}
            />
             <Route
              exact
              path={"/Activate/:email/:token"}
              render={(props) => <Activate{...props} />}
            />

<Route
              exact
              path={"/Update/:email/:token"}
              render={(props) => <Update {...props} />}
            />
              <Route
              exact
              path={"/Login"}
              render={(props) => <Login {...props} />}
            />
             <Route
              exact
              path={"/SignupChoir"}
              render={(props) => <AmtSignup {...props} />}
            />  

              <Route
              exact
              path={"/SignupTheatre"}
              render={(props) => <Theatre {...props} />}
            />  
 <Route
              exact
              path="/School-Thankyou"
              render={() => <SchoolThankyou />}
            />
            <Route
            exact
            path={"/PublisherSignup"}
            render={(props) => <PublisherSignup {...props} />}
          />
            
            <Route
            exact
            path={"/SignupMusic"}
            render={(props) => <MusicSignup {...props} />}
          /><Route
          exact
          path={"/SignupSchool"}
          render={(props) => <SchoolSignup {...props} />}
        />
        <Route
          exact
          path={"/SignupEducation"}
          render={(props) => <EduSignup {...props} />}
        />
         <Route
          exact
          path={"/Dashboard"}
          render={(props) => <Dashboard {...props} />}
        />
            <Route
              exact
              path={"/Thankyou"}
              render={(props) => <AmtThanks {...props} />}
            />

            <Route
              exact
              path={"/PublisherThankyou"}
              render={(props) => <PublisherThanks {...props} />}
            />

            
            <Route
            exact
            path={"/Main"}
            render={(props) => <Detail {...props} />}
            
          />
             
             <Route
            exact
            path={"/Privacy"}
            render={(props) => <Privacy {...props} />}
            
          />
          {/* {for fix routing issue add these new routes} */}
             <Route
            exact
            path={"/Main/Account"}
            render={(props) => <Account {...props} />}
            
          />
            <Route
            exact
            path={"/Main/Licences"}
            render={(props) => <Licence {...props} />}
          />
            <Route
            exact
            path={"/Main/Listing"}
            render={(props) => <Listing {...props} />}
            />
          <Route
            exact
            path={"/Main/Resources/:age?/:id?"}
            render={(props) => <Resource {...props} />}
          />
    
    <Route
            exact
            path={"/Invalid/resource"}
            render={(props) => <ResourceNotfound {...props} />}
          />
            <Route component={Notfound} />
            
          </Switch>
        </Suspense>
      </Router>
    );
  }
}

export default Routes;
