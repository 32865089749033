import { ROLE} from "../actions/actionsTypes";

const initialState = {
  rRole: '',
};

const roleReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case ROLE:
      return {
        ...state,
        rRole: actions.payload,
      };
   

    default:
      return state;
  }
};

export default roleReducer;