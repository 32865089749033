import { LICENCEE } from "../actions/actionsTypes";
import cookie from "react-cookies";
const initialState = {
rLic: cookie.load('Lic')==undefined?"main":cookie.load('Lic'),
  //rLic:'done'
};

const licReducer = (state = initialState, actions) => {

  switch (actions.type) {
    case LICENCEE:
      return {
        ...state,
        rLic: actions.payload,
      };
   

    default:
      return state;
  }
};

export default licReducer ;
