import { WORK} from "../actions/actionsTypes";

const initialState = {
  rWorks: null,
};

const workReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case WORK:
      return {
        ...state,
        rWorks: actions.payload,
      };
   

    default:
      return state;
  }
};

export default workReducer;