import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { Component }  from 'react';
import './assets/css/bootstrap.min.css';
import 'antd/dist/antd.css';
import './App.css';
import Routes from './Routes';
import './assets/css/style.css';

 class App extends Component {
  render() {
      return (
          <div>
             <Routes/>
          </div>
      )
  }
}


export default App;
