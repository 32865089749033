
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import 'fast-text-encoding/text';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {Provider} from 'react-redux'
import store from './store'
import reportWebVitals from './reportWebVitals';
import GA4React, { useGA4React } from "ga-4-react";
import { GA_TRACKING_ID } from "./Services/Api/Constant";

const ga4react = new GA4React(GA_TRACKING_ID);
( async()=>{
  await ga4react.initialize();
  ReactDOM.render(
    <Provider store={store}>
   <React.StrictMode>
      <App />
    </React.StrictMode>
    </Provider>,
    document.getElementById('root')
  );
})()


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
